/* eslint-disable no-underscore-dangle */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */

export const MovingAverages8EMA = (pjs) => ({
  name: 'Moving Averages 8 EMA',
  metainfo: {
    _metainfoVersion: 51,
    id: 'MovingAverages8@tv-basicstudies-1',
    description: 'Moving Averages 8 EMA',
    shortDescription: '8 EMA',
    is_price_study: true,
    isCustomIndicator: true,
    linkedToSeries: true,
    plots: [
      {
        id: 'plot_0',
        type: 'line',
        palette: 'paletteId1',
      },
    ],
    palettes: {
      paletteId1: {
        colors: {
          0: {
            name: 'First color',
          },
        },
      },
    },
    defaults: {
      palettes: {
        paletteId1: {
          colors: {
            0: {
              color: '#F17358',
              width: 3,
              style: 0,
            },
          },
        },
      },
      styles: {
        plot_0: {
          linestyle: 0,
          visible: true,
          linewidth: 3,
          plottype: 0,
          trackPrice: false,
          color: '#F17358',
        },
      },
    },
    styles: {
      plot_0: {
        title: 'Moving Averages 8 EMA',
        histogramBase: 0,
      },
    },
    inputs: [],
    format: {
      type: 'price',
      precision: 4,
    },
  },
  constructor: function () {
    this.main = function (context, inputCallback) {
      this._context = context;
      this._input = inputCallback;

      const source = this._context.new_var(pjs.Std.close(this._context));

      const fast = pjs.Std.ema(source, 8, this._context);

      return [fast];
    };
  },
});

export const MovingAverages21EMA = (pjs) => ({
  name: 'Moving Averages 21 EMA',
  metainfo: {
    _metainfoVersion: 51,
    id: 'MovingAverages21@tv-basicstudies-1',
    description: 'Moving Averages 21 EMA',
    shortDescription: '21 EMA',
    is_price_study: true,
    isCustomIndicator: true,
    linkedToSeries: true,
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
    ],
    palettes: {
      paletteId1: {
        valToIndex: {
          0: 0,
        },
        colors: {
          0: {
            name: 'First color',
          },
        },
      },
    },
    defaults: {
      palettes: {
        paletteId1: {
          colors: {
            0: {
              color: '#18B4CB',
              width: 3,
              style: 0,
            },
          },
        },
      },
      styles: {
        plot_0: {
          linestyle: 0,
          visible: true,
          linewidth: 3,
          plottype: 0,
          trackPrice: false,
          color: '#18B4CB',
        },
      },
    },
    styles: {
      plot_0: {
        title: 'Moving Averages 21 EMA',
        histogramBase: 0,
      },
    },
    inputs: [],
    format: {
      type: 'price',
      precision: 4,
    },
  },
  constructor: function () {
    this.main = function (context, inputCallback) {
      this._context = context;
      this._input = inputCallback;

      const src = context.new_var(pjs.Std.close(context));
      const slow21 = pjs.Std.ema(src, 21, this._context);
      return [slow21];
    };
  },
});

export const MovingAverages55EMA = (pjs) => ({
  name: 'Moving Averages 55 EMA',
  metainfo: {
    _metainfoVersion: 51,
    id: 'MovingAverages55@tv-basicstudies-1',
    description: 'Moving Averages 55 EMA',
    shortDescription: '55 EMA',
    is_price_study: true,
    isCustomIndicator: true,
    linkedToSeries: true,
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
    ],
    palettes: {
      paletteId1: {
        colors: {
          0: {
            name: 'First color',
          },
        },
      },
    },
    defaults: {
      palettes: {
        paletteId1: {
          colors: {
            0: {
              color: '#E5C25B',
              width: 3,
              style: 0,
            },
          },
        },
      },
      styles: {
        plot_0: {
          linestyle: 0,
          visible: true,
          linewidth: 3,
          plottype: 0,
          trackPrice: false,
          color: '#E5C25B',
        },
      },
    },
    styles: {
      plot_0: {
        title: 'Moving Averages 55 EMA',
        histogramBase: 0,
      },
    },
    inputs: [],
    format: {
      type: 'price',
      precision: 4,
    },
  },
  constructor: function () {
    this.main = function (context, inputCallback) {
      this._context = context;
      this._input = inputCallback;

      const src = context.new_var(pjs.Std.close(this._context));
      const slow55 = pjs.Std.ema(src, 55, this._context);
      return [slow55];
    };
  },
});
