/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
const displayDebugLog = true;

export const GenesisCogMain = (pjs) => ({
  name: 'Genesis Cog Main',
  metainfo: {
    _metainfoVersion: 51,
    id: 'GenesisMain@tv-basicstudies-1',
    description: 'Genesis Cog Main',
    shortDescription: 'Genesis Cog Main',
    is_price_study: true,
    isCustomIndicator: true,
    linkedToSeries: true,
    plots: [
      // Strong Buy
      {
        id: 'strong_buy',
        type: 'shapes',
        plottype: 'shape_arrow_up',
        location: 'BelowBar',
        color: '#00ff00',
      },
      // Buy
      {
        id: 'buy',
        type: 'shapes',
        plottype: 'shape_arrow_up',
        location: 'BelowBar',
        color: '#009600',
      },
      // Strong Sell
      {
        id: 'strong_sell',
        type: 'shapes',
        plottype: 'shape_arrow_down',
        location: 'AboveBar',
        color: '#ff0000',
      },
      // Sell
      {
        id: 'sell',
        type: 'shapes',
        plottype: 'shape_arrow_down',
        location: 'AboveBar',
        color: '#ffa600',
      },
    ],
    defaults: {
      palettes: {},
      styles: {
        strong_buy: {
          location: 'BelowBar',
          text: '',
          color: '#00ff00', // Green
          textColor: '#00ff00', // Green
          plottype: 'shape_arrow_up',
        },
        buy: {
          location: 'BelowBar',
          text: '',
          color: '#009600', // Dark Green
          textColor: '#009600', // Dark Green
          plottype: 'shape_arrow_up',
        },
        strong_sell: {
          location: 'AboveBar',
          text: '',
          color: '#ff0000', // Red
          textColor: '#ff0000', // Red
          plottype: 'shape_arrow_down',
        },
        sell: {
          location: 'AboveBar',
          text: '',
          color: '#ffa600', // Orange
          textColor: '#ffa600', // Orange
          plottype: 'shape_arrow_down',
        },
      },
      precision: 4,
      inputs: {
        rsi_length: 14,
        macd_fast: 12,
        macd_slow: 26,
        macd_signal: 9,
        score_threshold: 3.0,
        strong_threshold: 4.0,
        showDebugLog: false,
        useHeikinAshi: false,
      },
    },
    styles: {
      strong_buy: {
        title: 'Strong Buy',
        text: '',
      },
      buy: {
        title: 'Buy',
        text: '',
      },
      strong_sell: {
        title: 'Strong Sell',
        text: '',
      },
      sell: {
        title: 'Sell',
        text: '',
      },
    },
    inputs: [
      {id: 'rsi_length', name: 'RSI Length', type: 'integer', defval: 14},
      {id: 'macd_fast', name: 'MACD Fast', type: 'integer', defval: 12},
      {id: 'macd_slow', name: 'MACD Slow', type: 'integer', defval: 26},
      {id: 'macd_signal', name: 'MACD Signal', type: 'integer', defval: 9},
      {id: 'score_threshold', name: 'Score Threshold', type: 'float', defval: 3.0},
      {id: 'strong_threshold', name: 'Strong Threshold', type: 'float', defval: 4.0},
      {
        id: 'showDebugLog',
        name: 'Show Debug Log for last bar (console.log)',
        type: 'bool',
        defval: false,
      },
      {id: 'useHeikinAshi', name: 'Use Heikin Ashi', type: 'bool', defval: false},

    ],
    format: {
      type: 'price',
      precision: 4,
    },
  },
  constructor: function () {
    this.init = function (context, inputCallback) {
      const useHeikinAshi = inputCallback(7);
      const {ticker, tickerid} = context?.symbol;
      if (ticker) {
        const symbol = '={"symbol":"ACGL"}'.replace('ACGL', ticker);
        if (symbol !== tickerid) {
          const period = pjs.Std.period(context);
          context.new_sym(symbol, period);
          if (!useHeikinAshi) {
            context.select_sym(1);
          }
        }
      }
    };
    this.pineRma = function (src, length) {
      const alpha = 1 / length;
      const sum = 0.0;
      const sma = this.simpleMovingAverage(src, length);

      // Simulating Pine Script's recursive assignment
      const sumArray = [];
      for (let i = 0; i < src.length; i++) {
        if (i === 0) {
          sumArray.push(sma);
        } else {
          const previousSum = sumArray[i - 1] || 0;
          sumArray.push(alpha * src[i] + (1 - alpha) * previousSum);
        }
      }
      return sumArray;
    };
    this.pineRsi = function (x, y) {
      const u = [];
      const d = [];

      // Calculate upward and downward changes
      for (let i = 1; i < x.length; i++) {
        const change = x[i] - x[i - 1];
        u.push(Math.max(change, 0));
        d.push(Math.max(-change, 0));
      }

      // Calculate RMA of upward and downward changes
      const rmaU = this.pineRma(u, y);
      const rmaD = this.pineRma(d, y);

      // Calculate RSI
      const rs = rmaU.map((value, index) => value / (rmaD[index] || 1)); // Avoid division by zero
      const rsi = rs.map((value) => 100 - 100 / (1 + value));

      return rsi;
    };
    this.simpleMovingAverage = function (src, length) {
      const sma = [];
      for (let i = 0; i < src.length; i++) {
        if (i < length - 1) {
          sma.push(null); // Not enough data to calculate SMA
        } else {
          const sum = src.slice(i - length + 1, i + 1).reduce((a, b) => a + b, 0);
          sma.push(sum / length);
        }
      }
      return sma;
    };
    this.calculateEma = function (src, length) {
      const alpha = 2 / (length + 1);
      const emaArray = [];

      for (let i = 0; i < src.length; i++) {
        if (i === 0) {
          emaArray.push(src[i]); // Use the first value as the initial EMA
        } else {
          const previousEma = emaArray[i - 1];
          emaArray.push(alpha * src[i] + (1 - alpha) * previousEma);
        }
      }
      return emaArray;
    };
    this.calculateMacd = function (src, fastLength, slowLength, signalLength) {
      // Step 1: Calculate the Fast EMA
      const fastEma = this.calculateEma(src, fastLength);
      if (this._enableDebug && this._isLastBar) {
        console.log("fastLength:", fastLength);
        const last20 = fastEma?.length >= 20 ? fastEma.slice(fastEma.length - 20) : [];
        console.log("fastEma:", last20);
      }
      // Step 2: Calculate the Slow EMA
      const slowEma = this.calculateEma(src, slowLength);
      if (this._enableDebug && this._isLastBar) {
        console.log("slowLength:", slowLength);
        const last20 = slowEma?.length >= 20 ? slowEma.slice(slowEma.length - 20) : [];
        console.log("slowEma:", last20);
      }
      // Step 3: Calculate the MACD Line (Fast EMA - Slow EMA)
      const macdLine = fastEma.map((value, index) => value - (slowEma[index] || 0));
      if (this._enableDebug && this._isLastBar) {
        const last20 = macdLine?.length >= 20 ? macdLine.slice(macdLine.length - 20) : [];
        console.log("macdLine:", last20);
      }
      // Step 4: Calculate the Signal Line (EMA of MACD Line)
      const signalLine = this.calculateEma(macdLine, signalLength);

      // Step 5: Calculate the MACD Histogram (MACD Line - Signal Line)
      const histogram = macdLine.map((value, index) => value - (signalLine[index] || 0));

      // Return the components of MACD
      return {
        macdLine,
        signalLine,
        histogram,
      };
    };
    this.main = function (context, inputCallback) {
      this._context = context;
      const {isLastBar} = this._context.symbol;

      const rsiLength = inputCallback(0);
      const macdFast = inputCallback(1);
      const macdSlow = inputCallback(2);
      const macdSignal = inputCallback(3);
      const scoreThreshold = inputCallback(4);
      const strongThreshold = inputCallback(5);
      const enableDebug = inputCallback(6);
      const useHeikinAshi = inputCallback(7);
      if (useHeikinAshi && !this._context.is_main_symbol) {
        this._context.select_sym(0);
      } else if (!useHeikinAshi && this._context.is_main_symbol) {
        this._context.select_sym(1);
      }
      const close = this._context.new_unlimited_var(pjs.Std.close(this._context));
      this._enableDebug = enableDebug;
      this._isLastBar = isLastBar;
      this._showDebugLog = enableDebug && isLastBar;
      if (close.indexOf(pjs.Std.time(this._context, pjs.Std.period(context))) === -1) {
        return [0, 0, 0, 0];
      }

      if (Number.isNaN(close.get())) {
        return [0, 0, 0, 0];
      }
      if (this._showDebugLog) {
        console.log('symbol:', this._context.symbol.ticker);
        console.log('useHeikinAshi:', useHeikinAshi);
        console.log('close:', close.get());
      }
      const closeHistory = close._hist?.length
        ? close._hist.filter((price) => !Number.isNaN(price) && price !== 0)
        : [];
      const index = this._context.new_unlimited_var(this._context.symbol.index);
      const rsi = this._context.new_unlimited_var(
        closeHistory?.length ? this.pineRsi(closeHistory, rsiLength)[index - 1] : 0,
      );
      if (this._showDebugLog) {
        const last20 = closeHistory?.length >= 20 ? closeHistory.slice(closeHistory.length - 20) : [];
        console.log('closeHistory:', last20);
      }
      const macd = closeHistory?.length ? this.calculateMacd(closeHistory, macdFast, macdSlow, macdSignal) : 0;
      const macdSlope = this._context.new_unlimited_var(macd?.macdLine?.[index - 1] - macd?.macdLine?.[index - 2]);
      const macdHistory = macdSlope._hist?.length ? macdSlope._hist.filter((price) => !Number.isNaN(price) && price !== 0) : [];
      const ad = this._context.new_unlimited_var(pjs.Std.accdist(context));
      const adSlope = this._context.new_unlimited_var(((ad.get() - ad.get(1)) / ad.get(1)) * 100);
      const last20SignalLine = macd?.signalLine?.length >= 20 ? macd?.signalLine?.slice(macd?.signalLine?.length - 20) : [];
      const last20Histogram = macd?.histogram?.length >= 20 ? macd?.histogram?.slice(macd?.histogram?.length - 20) : [];
      const last20MacdSlope = macdHistory?.length >= 20 ? macdHistory?.slice(macdHistory?.length - 20) : [];
      if (this._showDebugLog) {
        console.log('rsi:', rsi.get());
        console.log('MACDfast:', macd?.macdLine?.[index - 1]);
        console.log('MACDfast[1]:', macd?.macdLine?.[index - 2]);
        console.log('MACDslow:', macd?.signalLine?.[index - 1]);
        console.log('MACDhist:', macd?.histogram?.[index - 1]);
        console.log('MACDsignalLine:', last20SignalLine);
        console.log('MACDhistogram:', last20Histogram);
        console.log('MacdSlope History:', last20MacdSlope);
        console.log('macdSlope:', macdSlope.get());
        console.log('ad:', ad.get());
        console.log('ad[1]:', ad.get(1));
        console.log('adSlope:', adSlope.get());
        console.log('index:', index.get());
      }
      // Scoring System
      const rsiScore =
        rsi.get() < 35 ? 2.0 : rsi.get() < 45 ? 1.0 : rsi.get() > 65 ? -2.0 : rsi.get() > 55 ? -1.0 : 0.0;
      const macdScore =
        macdSlope.get() >= 0.1
          ? 1.5
          : macdSlope.get() >= 0.03
          ? 1.0
          : macdSlope.get() <= -0.1
          ? -1.5
          : macdSlope.get() <= -0.03
          ? -1.0
          : 0.0;
      const adScore = adSlope.get() > 0.005 ? 1.0 : adSlope.get() < -0.005 ? -1.0 : 0.0;

      const totalScore = this._context.new_unlimited_var(rsiScore + macdScore + adScore);
      if (!totalScore.get) {
        return [0, 0, 0, 0];
      }
      if (Number.isNaN(totalScore.get()) || Number.isNaN(totalScore.get(1))) {
        return [0, 0, 0, 0];
      }

      // Signal Generation
      const buySignal = totalScore.get() >= scoreThreshold && totalScore.get(1) < scoreThreshold;
      const strongBuySignal = totalScore.get() >= strongThreshold && totalScore.get(1) < strongThreshold;
      const sellSignal = totalScore.get() <= -scoreThreshold && totalScore.get(1) > -scoreThreshold;
      const strongSellSignal = totalScore.get() <= -strongThreshold && totalScore.get(1) > -strongThreshold;

      if (this._showDebugLog) {
        console.log('totalScore:', totalScore.get());
        console.log('buySignal:');
        console.log('total_score >= score_threshold and total_score[1] < score_threshold:', buySignal);
        console.log('total_score >= score_threshold:', totalScore.get() >= scoreThreshold);
        console.log('total_score[1] < score_threshold:', totalScore.get(1) < scoreThreshold);
        console.log('strongBuySignal:');
        console.log('total_score >= strong_threshold and total_score[1] < strong_threshold:', strongBuySignal);
        console.log('total_score >= strong_threshold:', totalScore.get() >= strongThreshold);
        console.log('total_score[1] < strong_threshold:', totalScore.get(1) < strongThreshold);
        console.log('sellSignal:');
        console.log('total_score <= -score_threshold and total_score[1] > -score_threshold:', sellSignal);
        console.log('total_score <= -score_threshold:', totalScore.get() <= -scoreThreshold);
        console.log('total_score[1] > -score_threshold:', totalScore.get(1) > -scoreThreshold);
        console.log('strongSellSignal:');
        console.log('total_score <= -strong_threshold and total_score[1] > -strong_threshold:', strongSellSignal);
        console.log('total_score <= -strong_threshold:', totalScore.get() <= -strongThreshold);
        console.log('total_score[1] > -strong_threshold:', totalScore.get(1) > -strongThreshold);
      }

      // Plot Signals
      const plotValues = [0, 0, 0, 0];
      if (buySignal || strongBuySignal) {
        if (strongBuySignal) {
          plotValues[0] = 1;
          plotValues[1] = 0;
        } else if (buySignal) {
          plotValues[0] = 0;
          plotValues[1] = 1;
        }
      }

      if (sellSignal || strongSellSignal) {
        if (strongSellSignal) {
          plotValues[2] = 1;
          plotValues[3] = 0;
        } else if (sellSignal) {
          plotValues[2] = 0;
          plotValues[3] = 1;
        }
      }

      return plotValues;
    };
  },
});

export const GenesisCogLower = (pjs) => ({
  name: 'Genesis Cog Lower',
  metainfo: {
    _metainfoVersion: 51,
    id: 'GenesisLower@tv-basicstudies-1',
    description: 'Genesis Cog Lower',
    shortDescription: 'Genesis Cog Lower',
    is_price_study: false,
    isCustomIndicator: true,
    linkedToSeries: false,
    plots: [
      {
        id: 'bars',
        type: 'line',
      },
      {
        id: 'bars_colorer',
        type: 'colorer',
        target: 'bars',
        palette: 'paletteId1',
      },
      // Strong Upper Threshold
      {
        id: 'strong_upper_threshold',
        type: 'line',
        color: '#00ff00',
      },
      // Upper Threshold
      {
        id: 'upper_threshold',
        type: 'line',
        color: '#00ff00',
      },
      // Lower Threshold
      {
        id: 'lower_threshold',
        type: 'line',
        color: '#ffa600',
      },
      // Strong Lower Threshold
      {
        id: 'strong_lower_threshold',
        type: 'line',
        color: '#ff0000',
      },
      // Zero Line
      {
        id: 'zero_line',
        type: 'line',
        color: '#404040',
      },
    ],
    palettes: {
      paletteId1: {
        colors: [
          {name: 'Buy', color: '#009600'}, // Bright Green
          {name: 'Strong Buy', color: '#00ff00'}, // Dark Green
          {name: 'Sell', color: '#ffa600'}, // Orange
          {name: 'Strong Sell', color: '#ff0000'}, // Red
          {name: 'Neutral', color: '#404040'}, // Gray
        ],
        valToIndex: {0: 0, 1: 1, 2: 2, 3: 3, 4: 4},
      },
    },
    defaults: {
      palettes: {
        paletteId1: {
          colors: [
            {name: 'Buy', color: '#009600', width: 3, style: 1}, // Bright Green
            {name: 'Strong Buy', color: '#00ff00', width: 3, style: 1}, // Dark Green
            {name: 'Sell', color: '#ffa600', width: 3, style: 1}, // Orange
            {name: 'Strong Sell', color: '#ff0000', width: 3, style: 1}, // Red
            {name: 'Neutral', color: '#404040', width: 3, style: 1}, // Gray
          ],
        },
      },
      styles: {
        bars: {
          linestyle: 0,
          width: 6,
          plottype: 5,
          trackPrice: false,
          transparency: 0,
          visible: true,
          linewidth: 3,
        },
        // Strong Upper Threshold
        strong_upper_threshold: {
          linestyle: 0,
          visible: true,
          linewidth: 3,
          plottype: 0,
          trackPrice: false,
          color: '#00ff00', // Bright Green
        },
        // Upper Threshold
        upper_threshold: {
          linestyle: 0,
          visible: true,
          linewidth: 3,
          plottype: 0,
          trackPrice: false,
          color: '#009600', // Dark Green
        },
        // Lower Threshold
        lower_threshold: {
          linestyle: 0,
          visible: true,
          linewidth: 3,
          plottype: 0,
          trackPrice: false,
          color: '#ffa600', // Orange
        },
        // Strong Lower Threshold
        strong_lower_threshold: {
          linestyle: 0,
          visible: true,
          linewidth: 3,
          plottype: 0,
          trackPrice: false,
          color: '#ff0000', // Red
        },
        // Zero Line
        zero_line: {
          linestyle: 0,
          visible: true,
          linewidth: 3,
          plottype: 0,
          trackPrice: false,
          color: '#404040',
        },
      },
      precision: 4,
      inputs: {
        rsi_length: 14,
        macd_fast: 12,
        macd_slow: 26,
        macd_signal: 9,
        score_threshold: 3.0,
        strong_threshold: 4.0,
        showDebugLog: displayDebugLog,
        useHeikinAshi: false,
      },
    },
    styles: {
      bars: {
        title: 'Plot Score Histogram',
        histogramBase: 0,
      },
    },
    inputs: [
      {id: 'rsi_length', name: 'RSI Length', type: 'integer', defval: 14},
      {id: 'macd_fast', name: 'MACD Fast', type: 'integer', defval: 12},
      {id: 'macd_slow', name: 'MACD Slow', type: 'integer', defval: 26},
      {id: 'macd_signal', name: 'MACD Signal', type: 'integer', defval: 9},
      {id: 'score_threshold', name: 'Score Threshold', type: 'float', defval: 3.0},
      {id: 'strong_threshold', name: 'Strong Threshold', type: 'float', defval: 4.0},
      {id: 'showDebugLog', name: 'Show Debug Log for last bar (console.log)', type: 'bool', defval: displayDebugLog},
      {id: 'useHeikinAshi', name: 'Use Heikin Ashi', type: 'bool', defval: false},
    ],
    format: {
      type: 'price',
      precision: 4,
    },
  },
  constructor: function () {
    this.init = function (context, inputCallback) {
      const useHeikinAshi = inputCallback(7);
      const {ticker, tickerid} = context?.symbol;
      if (ticker && !useHeikinAshi) {
        const symbol = '={"symbol":"ACGL"}'.replace('ACGL', ticker);
        if (symbol !== tickerid) {
          const period = pjs.Std.period(context);
          context.new_sym(symbol, period);
          if (!useHeikinAshi) {
            context.select_sym(1);
          }
        }
      }
    };
    this.pineRma = function (src, length) {
      const alpha = 1 / length;
      const sum = 0.0;
      const sma = this.simpleMovingAverage(src, length);

      // Simulating Pine Script's recursive assignment
      const sumArray = [];
      for (let i = 0; i < src.length; i++) {
        if (i === 0) {
          sumArray.push(sma);
        } else {
          const previousSum = sumArray[i - 1] || 0;
          sumArray.push(alpha * src[i] + (1 - alpha) * previousSum);
        }
      }
      return sumArray;
    };
    this.pineRsi = function (x, y) {
      const u = [];
      const d = [];

      // Calculate upward and downward changes
      for (let i = 1; i < x.length; i++) {
        const change = x[i] - x[i - 1];
        u.push(Math.max(change, 0));
        d.push(Math.max(-change, 0));
      }

      // Calculate RMA of upward and downward changes
      const rmaU = this.pineRma(u, y);
      const rmaD = this.pineRma(d, y);

      // Calculate RSI
      const rs = rmaU.map((value, index) => value / (rmaD[index] || 1)); // Avoid division by zero
      const rsi = rs.map((value) => 100 - 100 / (1 + value));

      return rsi;
    };
    this.simpleMovingAverage = function (src, length) {
      const sma = [];
      for (let i = 0; i < src.length; i++) {
        if (i < length - 1) {
          sma.push(null); // Not enough data to calculate SMA
        } else {
          const sum = src.slice(i - length + 1, i + 1).reduce((a, b) => a + b, 0);
          sma.push(sum / length);
        }
      }
      return sma;
    };
    this.calculateEma = function (src, length) {
      const alpha = 2 / (length + 1);
      const emaArray = [];

      for (let i = 0; i < src.length; i++) {
        if (i === 0) {
          emaArray.push(src[i]); // Use the first value as the initial EMA
        } else {
          const previousEma = emaArray[i - 1];
          emaArray.push(alpha * src[i] + (1 - alpha) * previousEma);
        }
      }
      return emaArray;
    };
    this.calculateMacd = function (src, fastLength, slowLength, signalLength) {
      // Step 1: Calculate the Fast EMA
      const fastEma = this.calculateEma(src, fastLength);
      if (this._enableDebug && this._isLastBar) {
        console.log("fastLength:", fastLength);
        const last20 = fastEma?.length >= 20 ? fastEma.slice(fastEma.length - 20) : [];
        console.log("fastEma:", last20);
      }
      // Step 2: Calculate the Slow EMA
      const slowEma = this.calculateEma(src, slowLength);
      if (this._enableDebug && this._isLastBar) {
        console.log("slowLength:", slowLength);
        const last20 = slowEma?.length >= 20 ? slowEma.slice(slowEma.length - 20) : [];
        console.log("slowEma:", last20);
      }
      // Step 3: Calculate the MACD Line (Fast EMA - Slow EMA)
      const macdLine = fastEma.map((value, index) => value - (slowEma[index] || 0));
      if (this._enableDebug && this._isLastBar) {
        const last20 = macdLine?.length >= 20 ? macdLine.slice(macdLine.length - 20) : [];
        console.log("macdLine:", last20);
      }
      // Step 4: Calculate the Signal Line (EMA of MACD Line)
      const signalLine = this.calculateEma(macdLine, signalLength);

      // Step 5: Calculate the MACD Histogram (MACD Line - Signal Line)
      const histogram = macdLine.map((value, index) => value - (signalLine[index] || 0));

      // Return the components of MACD
      return {
        macdLine,
        signalLine,
        histogram,
      };
    };
    this.main = function (context, inputCallback) {
      this._context = context;
      const {isLastBar} = this._context.symbol;
      const rsiLength = inputCallback(0);
      const macdFast = inputCallback(1);
      const macdSlow = inputCallback(2);
      const macdSignal = inputCallback(3);
      const scoreThreshold = inputCallback(4);
      const strongThreshold = inputCallback(5);
      const enableDebug = inputCallback(6);
      const useHeikinAshi = inputCallback(7);

      this._enableDebug = enableDebug;
      this._isLastBar = isLastBar;
      this._showDebugLog = enableDebug && isLastBar;

      if (useHeikinAshi && !this._context.is_main_symbol) {
        this._context.select_sym(0);
      } else if (!useHeikinAshi && this._context.is_main_symbol) {
        this._context.select_sym(1);
      }

      const plotValues = [0, 4, strongThreshold, scoreThreshold, -scoreThreshold, -strongThreshold, 0];

      const close = this._context.new_unlimited_var(pjs.Std.close(this._context));

      if (this._showDebugLog) {
        console.log('symbol:', this._context.symbol.ticker);
        console.log('useHeikinAshi:', useHeikinAshi);
        console.log('close:', close.get());
      }
      const closeHistory = close._hist?.length
        ? close._hist.filter((price) => !Number.isNaN(price) && price !== 0)
        : [];
      const index = this._context.new_unlimited_var(this._context.symbol.index);
      const rsi = this._context.new_unlimited_var(
        closeHistory?.length ? this.pineRsi(closeHistory, rsiLength)[index.get() - 1] : 0,
      );
      if (this._showDebugLog) {
        const last20 = closeHistory?.length >= 20 ? closeHistory.slice(closeHistory.length - 20) : [];
        console.log('closeHistory:', last20);
      }
      const macd = closeHistory?.length ? this.calculateMacd(closeHistory, macdFast, macdSlow, macdSignal) : {};
      const macdSlope = this._context.new_unlimited_var(macd?.macdLine?.[macd?.macdLine?.length - 1] - macd?.macdLine?.[macd?.macdLine?.length - 2]);
      const macdHistory = macdSlope._hist?.length ? macdSlope._hist.filter((price) => !Number.isNaN(price) && price !== 0) : [];
      const ad = this._context.new_unlimited_var(pjs.Std.accdist(this._context));
      const adHistory = ad._hist?.length ? ad._hist.filter((price) => !Number.isNaN(price) && price !== 0) : [];
      const adSlope = this._context.new_unlimited_var(((adHistory[adHistory?.length - 1] - adHistory[adHistory?.length - 2]) / adHistory[adHistory?.length - 2]) * 100);
      const last20SignalLine = macd?.signalLine?.length >= 20 ? macd?.signalLine?.slice(macd?.signalLine?.length - 20) : [];
      const last20Histogram = macd?.histogram?.length >= 20 ? macd?.histogram?.slice(macd?.histogram?.length - 20) : [];
      const last20MacdSlope = macdHistory?.length >= 20 ? macdHistory?.slice(macdHistory?.length - 20) : [];
      if (this._showDebugLog) {
        console.log('rsi:', rsi.get());
        console.log('macdcalc', macd);
        console.log('MACDfast:', macd?.macdLine?.[macd?.macdLine?.length - 1]);
        console.log('MACDfast[1]:', macd?.macdLine?.[index - 1]);
        console.log('MACDslow:', macd?.signalLine?.[index - 1]);
        console.log('MACDhist:', macd?.histogram?.[index - 1]);
        console.log('MACDsignalLine:', last20SignalLine);
        console.log('MACDhistogram:', last20Histogram);
        console.log('MacdSlope History:', last20MacdSlope);
        console.log('macdSlope:', macdSlope.get());
        console.log('adHistory:', adHistory);
        console.log('ad:', ad.get(), adHistory[adHistory?.length - 1]);
        console.log('ad[1]:', ad.get(1), adHistory[adHistory?.length - 2]);
        console.log('adSlope:', adSlope.get());
        console.log('index:', index.get());
      }
      // Scoring System
      const rsiScore =
        rsi.get() < 35 ? 2.0 : rsi.get() < 45 ? 1.0 : rsi.get() > 65 ? -2.0 : rsi.get() > 55 ? -1.0 : 0.0;
      const macdScore =
        macdSlope.get() >= 0.1
          ? 1.5
          : macdSlope.get() >= 0.03
          ? 1.0
          : macdSlope.get() <= -0.1
          ? -1.5
          : macdSlope.get() <= -0.03
          ? -1.0
          : 0.0;
      const adScore = adSlope.get() > 0.005 ? 1.0 : adSlope.get() < -0.005 ? -1.0 : 0.0;

      const totalScore = this._context.new_unlimited_var(rsiScore + macdScore + adScore);
      if (this._showDebugLog) {
        console.log('rsiScore:', rsiScore);
        console.log('macdScore:', macdScore);
        console.log('adScore:', adScore);
        console.log('totalScore:', totalScore.get());
      }

      if (Number.isNaN(totalScore.get())) {
        return plotValues;
      }
      const scorevalue = !Number.isNaN(totalScore.get()) ? totalScore.get() : 0;
      // Color Logic
      let scoreColor = 4;

      if (scorevalue >= scoreThreshold) {
        if (scorevalue >= strongThreshold) {
          scoreColor = 1; // Green
        } else {
          scoreColor = 0; // Dark Green
        }
      }

      if (scorevalue <= -scoreThreshold) {
        if (scorevalue <= -strongThreshold) {
          scoreColor = 3; // Red
        } else {
          scoreColor = 2; // Orange
        }
      }

      if (this._showDebugLog) {
        console.log('total_score >= strong_threshold (Bright Green):', scorevalue >= strongThreshold);
        console.log('total_score >= score_threshold (Dark Green):', scorevalue >= scoreThreshold);
        console.log('total_score <= -strong_threshold (Red):', scorevalue <= -strongThreshold);
        console.log('total_score <= -score_threshold (Orange):', scorevalue <= -scoreThreshold);
        console.log('scorevalue:', scorevalue);
        console.log('scoreColor:', scoreColor);
      }

      // Plot Score Histogram
      plotValues[0] = scorevalue;
      plotValues[1] = scoreColor;

      return plotValues;
    };
  },
});
